@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

@include foundation-global-styles;
// @include foundation-grid;
@include foundation-flex-grid;
//
// @include foundation-xy-grid-classes;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-card;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
// @include foundation-float-classes;
@include foundation-flex-classes;

// @include foundation-prototype-classes;

@include motion-ui-transitions;
@include motion-ui-animations;



html, body{
	font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
	@include breakpoint(small only) {
		font-size: 13px;
	}
}


.big-button.button{
	font-size: 1.5rem;
	background-color: transparent;
	border: 3px solid #fff;
	font-weight: 700;
	border-radius: 10px;
	padding: 0.925rem 2.25rem;
	
}
.plus-abs{
	position: absolute;
}
.section h3{
	@include breakpoint(small only) {
		margin-left: 0 !important;
		text-align: center;
		margin-right: 0 !important;
		font-size: 2rem !important;
		padding-left: 10px !important;
		padding-right: 10px !important;
	}
}
.section{
	overflow: hidden;
	max-width:100%;
	max-width:100vw;
	position: relative;
}
.icon-block{
	display: flex;
	flex-direction: row;
	align-items: center;
	font-size: rem-calc(21);
	line-height: 1.2;
	font-weight: 600;
	letter-spacing: -0.5px;
	margin-right: rem-calc(8);
	margin-top: rem-calc(8);
}
#section1 {
	color: #fff;
	background: url('../img/section1.jpg') 50% 50% #d0392d no-repeat;
	background-size: cover;
	.header{
		@extend .row;
		@extend .align-middle;
		margin-right: auto;
		margin-left: auto;
		padding-top: 15px;
		padding-bottom: 15px;
		max-width: 980px;
		a, a:visited{
			text-decoration: none;
			color: #fff;
		}
		.logo-block{
			@extend .columns;
			@extend .small-5;
			@extend .medium-6;
			img{
				margin-left: 31px;
				@include breakpoint(small only) {
					margin-left: 0;
				}
			}
		}
	}
	.icon-block{
		justify-content: flex-end;	
	}
	.header-container {
		max-width: 780px;
		text-align: center;
		font-weight: bold;
		padding-top: 72px;
		padding-bottom: 104px;
		@include breakpoint(small only) {
			padding-left: 15px;
			padding-right: 15px;
		}
		h1{
			font-weight: bold;
			font-size: 3.625rem;
			line-height: 112%;
			margin-bottom: 4.5rem;
			@include breakpoint(small only) {
				font-size: 2.5rem;
				margin-bottom: 3.5rem;
			}
			
		}
		.orbittt{
			font-size: 1.5rem;
			margin-bottom: 5.125rem;
			.orbit-container{
				height: initial !important
			}
			.orbit-slide{
				width: 100%;
				display: inline-block;
				display: none;
				transition:0.5s;
				word-wrap: normal;
				margin-right: auto;
				margin-left: auto;
				animation: type 3.5s infinite;
				white-space: nowrap;
				overflow: hidden;
				animation-iteration-count: 1;
				&.is-active{
					display: inline-block;
					width: 100%;
				}
			}
		}
		.button{
			background-color: transparent;
			border-color: #fff;
		}
	}
}
@keyframes type{ 
	from { width: 0; } 
} 
#section2{
	padding-top: 51px;
	padding-bottom: 25px;
	.ul-block{
		@extend .column;
		@extend .small-12;
		flex: 0 0 52%;
		@include breakpoint(small only) {
			flex: 0 0 100%;
		}
	}
	h3{
		font-weight: bold;
		text-align: center;
		margin-bottom: 24px;
		padding-left: 53px;
		letter-spacing: -0.5px;
	}
	ul{
		font-size: rem-calc(20);
		line-height: rem-calc(25);
		padding-left: 0;
		list-style-type: none;
		li{
			padding-left: rem-calc(73);
			background: url('../img/check-icon.png') no-repeat 4% 49% ;
			margin-bottom: rem-calc(29);
		}
	}
	.img-block{
		@extend .columns;
		@include breakpoint(small only) {
			display: none;
		}
		img{
			position: absolute;
			margin-left: 90px;
			margin-top: 13px;
		}
	}
}

#section3{
	padding-top: 46px;
	padding-bottom: 58px;
	background-color: #f2f2f2;
	h3{
		@extend .row;
		@extend .column;
		text-align: center;
		letter-spacing: -0.5px;
		font-weight: bold;
	}
	.block{
		@extend .row;
		position: relative;
		padding-top: 40px;
		margin-right: auto;
		margin-left: auto;
		@include breakpoint(small only) {
			flex-direction: column;
			.columns{
				text-align: center !important;
				padding-left: 15px !important;
				padding-right: 15px !important;
				h4{
					font-size: 1.5rem;
					margin: 2rem auto;
					br{
						display: none;
					}
				}
				img{
					max-width: 50%;
					margin: 30px;
				}
			}
		}
		.columns{
			@extend .columns;
			@extend .medium-4;
			padding: 0 1.75rem;
			&__left{
				padding-right: 0;
			}
			&__center{
				padding: 0 0.7rem;
			}
			&__right{
				padding-left: 0;
				text-align: right;
			}
		}
		h4{
			font-weight: bold;
			margin-top: 4.125rem;
			line-height: 1.2;
			letter-spacing: -0.5px;
			margin-bottom: 1rem;
		}
		p{
			font-size: rem-calc(20);
			line-height: 1.2;
		}
	}
}

#section4{
	background: url('../img/section4-leaf.jpg') 100% 100% no-repeat transparent;
	h3{
		margin: 52px 0 19px 92px;
		letter-spacing: -0.5px;
		font-weight: bold;
	}
	.ol-block{
		@extend .column;
		@extend .small-12;
		@extend .large-7;
	}
	ol{
		counter-reset: section;
		list-style-type: none;
		font-size: rem-calc(24);
		margin-left: 1.4825rem;
		li{
			position: relative;
			height: 4.687rem;
			padding-left: 4.4125rem;
			display: flex;
			align-items: center;
			line-height: 1.2;
			@include breakpoint(small only) {
				font-size: 14px
			}
			&:before{
				background-color: #fff;
				z-index: 3;
				left: 0;
				counter-increment: section;
				content: counter(section); 
				position: absolute;
				width: 45px;
				border: 3px solid #f34336;
				color: #f34336;
				text-align: center;
				height: 45px;
				display: block;
				line-height: 39px;
				border-radius: 45px;
				font-family: 'Lora';
				font-weight: bold;
				font-size: 30px;
			}

			&:after{
				content:"";
				width: 3px;
				background-color: #f34336;
				top: 0%;
				bottom: 0;
				left: 21px;
				position: absolute;
			}
			&:first-child:after{
				top: 50%;
			}

			&:last-child:after{
				bottom: 50%;
			}
		}
	}
	.button.big-button{
		color: #fff;
		background-color: #f44336;
		border-color: #f44336;
		margin: 59px 0 68px;
	}
	img.section4__img{
		position: absolute;
		margin-left: 49px;
		margin-top: 47px;
		display: none;
		@include breakpoint(large){
			display: block;
		}
	}
}

#section5{
	background-color: #f44336;
	color: #fff;
	padding-top: 49px;
	padding-bottom: 15px;
	.row{
		max-width:60rem;
	}
	h3{
		font-weight: bold;
		letter-spacing: -0.5px;
		margin-bottom: 30px;
	}
	.sec5__block{
		@extend .column;
		@extend .small-12;
		@extend .medium-4;
		text-align: center;
		padding: 1.25rem 0.75rem 0.9375rem;
		@include breakpoint(small only) {
			h5 br{
				display: none;
			}
		}
		h5{
			font-weight: bold;
			font-size: 1.25rem;
			margin: 1.5rem auto;
			line-height: 1.3;
			
		}
		p{
			font-size: 1rem;
			line-height: 1.3;
		}
	}
}
#section6{
	background-color: #ebebeb;
	position: relative;
	@include breakpoint(small only) {
		.orbit-container{
			margin-bottom: 35px;
		}
		.orbit-slide-inner{
			flex-direction: column;
			img{
				max-width: 50%;
				margin-bottom: 15px;
			}
			.info{
				h3{
					font-size: 18px !important;
				}
				padding-right: 15px !important;
				padding-left: 15px !important;
				text-align: center;
			}
		}
	}
	>h3{
		font-weight: bold;
		position: absolute;
		left: 50%;
		padding-left: 90px;
		top: 30px;
		letter-spacing: -0.5px;
		@include breakpoint(small only) {
			position: relative;
			left: inherit;
			top: inherit;
			margin-top: 25px;
			margin-bottom: 30px;
			padding-left: 10px;
		}
	}
	.orbit{
		max-width: rem-calc(760);
		margin: 0 auto;
	}
	.orbit-slide{
		&-inner{
			display: flex;
			align-items: center;
			img{
				filter: grayscale(100%);
				transition: 0.7s;
				&:hover{
					filter: grayscale(0%);
				}
				@include breakpoint (small only){
					filter: grayscale(0%);
				}
			}
			.info{
				padding-left: 100px;
				// padding-top: 124px;
				h3{
					font-weight: bold;
					line-height: 1.2;
				}
				div{
					font-size: rem-calc(20);
					line-height: 1.2;
					margin-top: 18px;
				}
			}
		}
	}
}

#section7{
	background: url('../img/section7.jpg') center center no-repeat #f44336;
	color: #fff;
	padding: 49px 0 42px;
	h3{
		font-weight: bold;
		letter-spacing: -0.5px;
		margin-bottom: 52px;
	}
}

#section8{
	padding-top: 52px;
	padding-bottom: 42px;
	h3{
		@extend .row;
		@extend .column;
		font-weight: bold;
		text-align: center;
		&.red{
			margin-top: 50px;
			color: #f54337;
		}
	}
	.blocks-row{
		@extend .row;
		flex-flow: row;
		margin-top: 49px;
		display: flex;
		flex-direction: row;
		max-width: rem-calc(780);
		justify-content: space-between;
		img{
			align-self: center;
		}
		@include breakpoint(small only) {
			flex-direction: column;
    		max-width: 100%;
    		.block{
    			max-width: initial;
    			margin-left: 10px;
    			margin-right: 10px;
    		}
		}
	}
	.block{
		border-radius: 10px;
		border: 3px solid #2879fe;
		max-width: 28%;
		padding: 23px 20px 29px;
		text-align: center;
		font-size: 18px;
		line-height: 1.25;
	}
	.icons-row{
		@extend .row;
		max-width: rem-calc(920);
		margin-top: 30px;
		@include breakpoint(small only){
			div.column{
				flex: 0 0 100%;
				padding-left: 15px;
				padding-right: 15px;
				max-width: 100%;
			}
		}
		.medium-7{
			flex: 0 0 53%;
			max-width: 53%;
		}
		.medium-5{
			flex: 0 0 47%;
			max-width: 47%;
		}
		.icons-block{
			display: flex;
			flex-direction: row;
			align-items: center;
			.img{
				min-width: 40px;
			}
			p{
				margin: 0;
				padding: 17px 0 16px 15px;
				font-size: rem-calc(20);
				line-height: 1.2;
			}
		}
	}
}

#section9{
	background-color: #f3f3f3;
	padding-top: 50px;
	letter-spacing: -0.5px;
	padding-bottom: 30px;
	h3{
		@extend .column;
		@extend .row;
		font-weight: bold;
		text-align: center;
		margin-bottom: 51px;
	}
	.row{
		max-width: rem-calc(953);
		@include breakpoint(small only) {
			display: block;
			.column{
				margin-bottom: 20px;
			}
		}
	}
	.text-center{
		padding-left: 10px;
		padding-right: 10px;
	}
	.big-button{
		border-color: #f44336;
		background-color: #f44336;
		margin-top: 60px;
		margin-bottom: 30px;
		letter-spacing: 0;
	}
	.column{
		padding: 0 rem-calc(37);
		> div{
			border: 3px solid #f44336
		}
	}
}

#section10{
	background-color: #252525;
	color: #fff;
	font-size: rem-calc(20);
	@include breakpoint(small only){
		.row{
			display: block;
			iframe{
				height: 250px;
				margin-top: 30px;
			}
			.info{
				padding-left: 15px;
				max-width: 100%;
				font-size: 1.25rem;
				.icon-block{
					margin-top: 10px;
					margin-bottom: 10px;
					font-size: 1.125rem;
					img{
						max-width:20px;
					}
				}
			}
		}
	}
	a{
		color: #fff;
	}
	.info{
		padding-left: 80px;
		max-width: 50%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		.icon-block {
			margin-bottom: 30px;
			div+div{
				margin-left: 25px;	
			}
		}
		img{
			margin-right: 25px;
		}
	}
}

#section11{
	background-color: #101010;
	@include breakpoint(small only) {
		text-align: center;
		div.row{
			padding: 30px 0;
			text-align: center;
			font-size: rem-calc(18);
			display: block;
			min-height: initial;
		}
		padding-left: 15px;
		padding-right: 15px;
		.right{
			margin-top: 30px;
			text-align: center !important;
		}
	}
	img{
		margin: 0 15px;
	}
	.row{
		display: flex;
		min-height: 250px;
		align-items: center;
		color: #f44336;
		font-size: 1rem;
		br{
			display: inline;
			content: "|"
		}
		.right{
			flex: auto;
			text-align: right;
		}
	}
}
.reveal{
	box-shadow: 0 5px 15px rgba(#000, 0.3);
	position: relative;
	a.close-reveal-modal{
		color: #666;
		font-size: 28px;
		position: absolute;
		right: 20px;
		top: 10px;
	}
	form{
		margin: 13px auto !important;
		max-width: 460px;
		padding-top: 13px;
		padding-top: 1px;
		text-align: center;
	}
	h3{
		text-align: center;
		font-weight: bold;
		font-size: rem-calc(36);
		margin: rem-calc(30) 0;
	}
	input{
		border: 1px solid #f44336;
		margin: 19px 0 30px;
		height: 45px;
		font-size: rem-calc(24);
		padding-left:16px;
		&::placeholder {
			color:#666;
		}
	}
	p.text-center{
		font-size: rem-calc(22);
		margin-bottom: 30px;
	}
	.big-button{
		background-color: #f44336;
		border-color: #f44336;
		text-transform: uppercase;
		margin: 25px auto;
	}
}